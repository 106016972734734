import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slide" ]

  connect() {
    this.showCurrentSlide()

    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  next() {
    if (this.index < this.lastIndex) {
      this.index++;
    }
  }

  previous() {
    if (this.index > 0) {
      this.index--;
    }
  }

  // Auto Rotate
  rotate() {
    if (this.index < this.lastIndex) {
    	this.index++
  	} 
  	else {
  		this.index = 0
  	}
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index == i)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentSlide()
  }

  get lastIndex() {
    return this.slideElements.length - 1;
  }

  get slideElements() {
    return this.targets.findAll("slide");
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.rotate()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}