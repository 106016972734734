import { Controller } from "stimulus"

export default class extends Controller {

  close() {
		// if (typeof(this.elmnt) != 'undefined' && this.elmnt != null) {
    // https://stackoverflow.com/a/42526074
    if (this.elmnt) {
		  // Exists.
		  this.elmnt.classList.add("fadeinout")
			setTimeout(() => this.elmnt.remove(), 0.5 * 1000)
		}
  }

  get elmnt() {
  	return document.getElementById("doc_loader")
  }
}
